.loading-container.fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 0.5s linear;
}

.loading-container.fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 1s, opacity 0.5s linear;
}

.loading-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  z-index: 9999;
}

.loading {
  display: inline-block;
  position: relative;
  width: 138px;
  height: 138px;
}

@keyframes ldio-qgrgcihayo {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.ldio-qgrgcihayo div {
  position: absolute;
  animation: ldio-qgrgcihayo 1s linear infinite;
  box-sizing: border-box !important;
}

.ldio-qgrgcihayo div:nth-child(1) {
  width: 44px;
  height: 44px;
  left: 34px;
  top: 128px;
  border-radius: 50%;
  background: #292664;
}

.ldio-qgrgcihayo div:nth-child(2) {
  width: 66px;
  height: 66px;
  left: 56px;
  top: 84px;
  border-radius: 0 66px 0 0;
  border: 20px solid #667395;
  background: none;
  animation-delay: 0.1s;
  border-bottom: 0;
  border-left: 0;
}

.ldio-qgrgcihayo div:nth-child(3) {
  width: 110px;
  height: 110px;
  left: 56px;
  top: 40px;
  border-radius: 0 110px 0 0;
  border: 20px solid #94a9ce;
  background: none;
  animation-delay: 0.2s;
  border-bottom: 0;
  border-left: 0;
}

.loadingio-spinner-radio-nmqcy29klc {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}

.ldio-qgrgcihayo {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-qgrgcihayo div {
  box-sizing: content-box;
}
