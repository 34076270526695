@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  max-width: 550px;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #f9f9f9;
  cursor: pointer;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  border-radius: 10px;
  margin: 2px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.accordion .active {
  background-color: #f9f9f9;
}

.accordion:hover .accordion__title,
.active .accordion__title {
  font-weight: 400;
  color: #191919;
}

/* Style the accordion content title */
.accordion__title {
  font-size: 0.9vw;
  text-align: left;
  color: #7d7d7d;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: #fff;
  overflow: auto;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-weight: 400;
  font-size: 0.85vw;
  padding: 1rem;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .accordion {
    height: 80px;
  }
}

@media (max-width: 500px) {
  .accordion__title {
    font-size: 0.9rem;
  }

  .accordion__text {
    font-size: 0.8rem;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  .accordion__title {
    font-size: 1rem;
  }

  .accordion__text {
    font-size: 0.9rem;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .accordion__title {
    font-size: 1rem;
  }

  .accordion__text {
    font-size: 0.9rem;
  }
}
